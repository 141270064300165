<template>
  <TableWrapper class="table-data-view table-responsive">
    <a-table :pagination="false" :dataSource="dataSource" :loading="isLoading" :columns="columns">
      <template #customTitleSystem>
        <TableCustomTitle
          name="system"
          :sortData="{
            value: queryData.sort,
            order: queryData.order,
          }"
          @sortTable="sortTable"
        />
      </template>
      <template #customTitleWalletName>
        <TableCustomTitle
          name="name"
          :sortData="{
            value: queryData.sort,
            order: queryData.order,
          }"
          @sortTable="sortTable"
        />
      </template>
      <template #customTitleDate>
        <TableCustomTitle
          name="created_at"
          :sortData="{
            value: queryData.sort,
            order: queryData.order,
          }"
          @sortTable="sortTable"
        />
      </template>
      <template #customTitleStatus>
        <TableCustomTitle
          name="status"
          :sortData="{
            value: queryData.sort,
            order: queryData.order,
          }"
          @sortTable="sortTable"
        />
      </template>
      <template #customStatus="record">
        <p class="status-logo">
          <i><img :src="require(`@/static/img/icon/${record.record.system}.svg`)" class="crypto-icon" /></i>
          {{ record.record.system }}
        </p>
      </template>
    </a-table>
    <div style="text-align: right">
      <a-pagination
        :default-page-size="10"
        :show-size-changer="true"
        v-model:current="pagination.current"
        :total="pagination.count"
        @change="onChange"
        v-if="pagination"
      >
        <template #buildOptionText="props">
          <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
        </template>
      </a-pagination>
    </div>
  </TableWrapper>
</template>

<script>
import { defineAsyncComponent, computed, reactive, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import { TableWrapper } from '../../styled';
const TableCustomTitle = defineAsyncComponent(() => import('./TableCustomTitle'));

export default {
  props: {
    wallets: {
      type: Object,
      default() {
        return {};
      },
    },
    isLoading: {
      type: Boolean,
      defaul: true,
    },
  },

  components: {
    TableWrapper,
    TableCustomTitle,
  },
  setup(props) {
    const i18n = reactive(useI18n());
    const { dispatch } = useStore();
    const route = useRoute();
    const router = useRouter();

    const pagination = computed(() => props.wallets.meta);

    const columns = computed(() => [
      {
        dataIndex: 'system',
        key: 'system',
        slots: { title: 'customTitleSystem', customRender: 'customStatus' },
      },
      {
        dataIndex: 'name',
        key: 'name',
        slots: { title: 'customTitleWalletName' },
      },
      {
        title: i18n.t('editWalletView.walletNumber'),
        dataIndex: 'wallet',
        key: 'wallet',
      },
      {
        dataIndex: 'created_at',
        key: 'created_at',
        slots: { title: 'customTitleDate' },
      },
      {
        dataIndex: 'status',
        key: 'status',
        slots: { title: 'customTitleStatus' },
      },
      {
        title: i18n.t('editWalletView.comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: '',
        width: 0,
      },
    ]);

    const dataSource = computed(() =>
      props.wallets.data
        ? props.wallets.data.map((value, key) => {
            let { system, name, created_at, status, comment, wallet } = value.attributes;
            return {
              key: key + 1,
              system,
              name,
              wallet,
              created_at: i18n.d(created_at, 'short'),
              status: <p class={'status-' + status}> {i18n.t('editWalletView.' + status)}</p>,
              comment,
            };
          })
        : [],
    );

    const pageNum = computed(() => route.params.pageNum);

    const queryData = reactive({
      pageNum: pageNum.value,
      pageSize: 10,
      order: 'desc',
      sort: 'id',
      sortableLink: `&sort_by=id&order=desc`,
    });

    const reloadTable = () => {
      dispatch('walletsGetData', queryData);
    };

    const sortTable = (value) => {
      if (queryData.sort == value) {
        queryData.order == 'asc' ? (queryData.order = 'desc') : (queryData.order = 'asc');
      }

      queryData.sort = value;
      queryData.sortableLink = `&sort_by=${value}&order=${queryData.order}`;
      reloadTable();
    };

    const onChange = (page) => {
      router.push('/wallets/page/' + page);
      queryData.pageNum = page;
    };

    onMounted(() => {
      if (!props.wallets.length) {
        reloadTable();
      }
    });
    return {
      i18n,
      pagination,
      columns,
      dataSource,
      onChange,
      sortTable,
      queryData,
    };
  },
};
</script>

<style></style>
